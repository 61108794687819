import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

const StyledTestimonialContentBlock = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div.container__content {
    max-width: 750px;
    p {
      padding: 0 20px;
      color: #fff;
      text-align: center;
      font-size: 16px; 
    }
  }
  div.container__authorinfo {
    span.name {
      
    }
    span.author {
      margin-right: 5px;
      padding: 2px 8px;
      border-radius: 20px;
      color: #121212;
      background: #fff;
      font-size: 10px;
      line-height: 100%;
    }
    span.company {
      margin-left: 5px;
      font-size: 10px;
      color: #fff;
    }
  }
`

const TestimonialContentBlock = ({ content, name, company }) => {
  return (
    <Fade>
      <StyledTestimonialContentBlock>
        <div className="container__content">
          <p>{content}</p>
        </div>
        <div className="container__authorinfo">
          <span className="author">{name}</span>
          <span className="company">{company}</span>
        </div>
      </StyledTestimonialContentBlock>
    </Fade>
  )
}

TestimonialContentBlock.defaultProps = {

}

export default TestimonialContentBlock