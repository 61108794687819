import React from 'react'
import styled from 'styled-components'
import Section from '../../../../resuable/section/section'
import Column from '../../../../resuable/column/column'
import Row from '../../../../resuable/row/row'
import FeatureSummary from './feature-summary/feature-summary'
import { dimensions, colors } from '../../../../../utils/styles/styles'
import { ButtonType } from '../../../../resuable/button'
import { Link } from 'gatsby'

const FeaturePageSummaryContainer = styled.div`
  width: 100%;
  .container__title {
    margin-bottom: 6rem;
    padding: 0 20px;
    display: block;
    h2 {
      font-size: 3rem;
      text-align: center;
    }
    h3 {
      color: ${colors.lightGray};
      font-size: 1.8rem;
      font-weight: 400;
      text-align: center;
    }
  }
  .container__buttons {
    width: 100%;
    margin: 2rem 0rem 0rem;
    display: flex;
    justify-content: center;
    a {
      display: inline-flex;
      justify-content: center;
      button {
        display: inline-block;
      }
    }
  }
  @media (max-width: ${dimensions.tablet}) {
    padding: 30px 20px 30px;
    .container__title {
      margin-bottom: 0.8rem;
    }
  }
`

const FeaturePageSummary = ({ summary }) => {
  return (
    <Section
      fullwidth={true}
      background={`#0c0c0c`}
      backgroundSize="auto"
      border="none"
    >
      <FeaturePageSummaryContainer>
        <Row className="container__title">
          <h2>Marketing was never meant to be hard.</h2>
          <h3>Leadmirror knows what matters to you. Our mission is to drive your business success by making marketing hasslefree.</h3>
        </Row>
        <Row isCentered={true} fullwidth={false} gap="40px">
          {
            summary.map((summaryItem, index) => {
              return (
                <Column key={index} isCentered={true}>
                  <FeatureSummary 
                    summaryItem={summaryItem}
                  />
                </Column>
              )
            })
          }
        </Row>
        <Row className="container__buttons">
          <Link to="/pricing">
            <ButtonType.Base variant="secondary">
              Buy Now
            </ButtonType.Base>
          </Link>
        </Row>
      </FeaturePageSummaryContainer>
    </Section>
  )
}

FeaturePageSummary.defaultProps = {

}

export default FeaturePageSummary