import React from 'react'
import styled from 'styled-components'

import Meera from '../../../../../images/testimonial/Meera.jpg'
import Deepak from '../../../../../images/testimonial/Deepak.jpg'
import Bhushan from '../../../../../images/testimonial/Bhushan.jpg'


const StyledTestimonialControlBlock = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  margin: 30px 0px 0px;
  display: flex;
  justify-content: center;
  div.container__image {
    margin: 0px 5px;
    padding: 4px 6px;
    border-bottom: 4px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:nth-child(${props => props.testimonialIndex + 1}) {
      img {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
      }
      span {
        background: yellow;
      }
    }
    img {
      height: 50px;
      width: 50px;
      margin-bottom: 6px;
      background: #222;
      border-radius: 50%;
    }
    span {
      width: 24px;
      height: 4px;
      display: inline-block;
      background: transparent;
    }
  }
`

const TestimonialControlBlock = (props) => {
  return (
    <StyledTestimonialControlBlock
      {...props}
    >
      <div className="container__image" id="0" onClick={props.handleActiveTestimonial}>
        <img src={Meera} alt="one" />
        <span></span>
      </div>
      <div className="container__image" id="1" onClick={props.handleActiveTestimonial}>
        <img src={Deepak} alt="two" />
        <span></span>
      </div>
      <div className="container__image" id="2" onClick={props.handleActiveTestimonial}>
        <img src={Bhushan} alt="three" />
        <span></span>
      </div>
    </StyledTestimonialControlBlock>
  )
}

TestimonialControlBlock.defaultProps = {

}

export default TestimonialControlBlock