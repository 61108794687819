/**
 * ! useStaticQuery to query all image data inside images/testimonial
 * ! Then mapping data and supllying to testimonial
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Section from '../../../resuable/section/section';
import TestimonialContentBlock from './testimonial-content-block/testimonial-content-block';
import { colors } from '../../../../utils/styles/styles';
import TestimonialControlBlock from './testimonial-control-block/testimonial-control-block';

// const testimonialData = [
//   {
//     name: "Lakshmi Krishna",
//     company: "King Chess Academy",
//     content: "Lead Mirror is fairly easy to use. I just had the right set of parameters to deal with. Even though there are other tools, LM does best in sorting the noise from the symphony.",
//     imgData: null
//   },

//   {
//     name: "Deepak",
//     company: "JMJ Travels",
//     content: "I was able to mine more than 2000 backlink sources within an hour. The free ones among them really helped me increase my backlinks and  scale my Domain authority at no extra cost .",
//     imgData: null
//   },

//   {
//     name: "Meera",
//     company: "Open Diaries",
//     content: "Lead mirror has been very helpful to us in the learning the basics of seo...We launched our website the open diaires with basic knowledge of seo...And we're very happy with the learning we've got so far. All the best you guys for a bright future ahead!",
//     imgData: null
//   },

// ] 

const StyledHomeTestimonialSection = styled.div`
  width: 100%;
  margin: 90px 0px 60px;
  div.container__inner {
    position: relative;
    width: 100%;
    height: 320px;
    padding: 40px 0px;
    background: ${colors.secGradient};
    box-shadow: 0px 0px rgba(0, 0, 0, 0.35);
    color: #fff;
    border-radius: 5px;
    div.container__quotation {
      font-size: 80px;
      line-height: 60%;
      font-family: serif;
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    margin: 60px 0px 20px;
    padding: 0 20px;
    div.container__inner {
      height: 360px;
      padding: 40px 0px;
    }
  }
  @media (max-width: 480px) {
    margin: 40px 0px 40px;
    padding: 0 20px;
    div.container__inner {
      height: 420px;
      padding: 40px 0px;
    }
  }
`

const HomeTestimonialSection = () => {

  const data = useStaticQuery(graphql`
    query testimonialData {
      allWordpressAcfTestimonials {
        nodes {
          acf {
            author
            company
            content
            designation
          }
        }
      }
    }  
  `)
  const { nodes } = data.allWordpressAcfTestimonials;

  console.log(nodes)

  const [testimonialIndex, setTestimonialIndex] = useState(1);

  const handleActiveTestimonial = (e) => {
    let activeIndex = parseInt(e.currentTarget.id) || 0
    setTestimonialIndex(activeIndex)
  }

  return (
    <Section
      padding="0px"
    >
      <StyledHomeTestimonialSection>
        <div className="container__inner">
          <div className="container__quotation">
            “
        </div>
          {
            testimonialIndex === 0 && (
              <TestimonialContentBlock
                name="Meera"
                company="Open Diaries"
                content="Lead mirror has been very helpful to us in the learning the basics of seo...We launched our website the open diaires with basic knowledge of seo...And we're very happy with the learning we've got so far. All the best you guys for a bright future ahead!"
              />
            )
          }
          {
            testimonialIndex === 1 && (
              <TestimonialContentBlock
                name="Deepak"
                company="JMJ Travels"
                content="I was able to mine more than 2000 backlink sources within an hour. The free ones among them really helped me increase my backlinks and scale my Domain authority at no extra cost ."
              />
            )
          }
          {
            testimonialIndex === 2 && (
              <TestimonialContentBlock
                name="Lakshmi Krishna"
                company="King Chess Academy"
                content="Lead Mirror is fairly easy to use. I just had the right set of parameters to deal with. Even though there are other tools, LM does best in sorting the noise from the symphony."
              />
            )
          }
          <TestimonialControlBlock
            testimonialIndex={testimonialIndex}
            handleActiveTestimonial={handleActiveTestimonial}
          />
        </div>
      </StyledHomeTestimonialSection>
    </Section>
  )
}

export default HomeTestimonialSection