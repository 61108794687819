import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../../../../utils/styles/styles'

import AngleArrowLeft from '../../../../../../assets/icons/angle-arrow-left.inline.svg'
import AngleArrowRight from '../../../../../../assets/icons/angle-arrow-right.inline.svg'


const HighlightsControlContainer = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  button {
    width: 36px;
    height: 36px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 2px solid ${colors.white};
    span {
      font-size: initial;
      line-height: 0px;
    }
    &:first-child {
      margin-right: 6px;
    }
    &:last-child {
      margin-left: 6px;
    }
    &:hover {
      background: ${colors.white};
      span svg path {
        stroke: ${colors.black};
      }
    }
  }
  @media (max-width: 768px) {
    position: static;
  }
`

const HighlightsControl = (props) => {
  return (
    <HighlightsControlContainer>
      <button onClick={props.handlePrevious}>
        <span><AngleArrowLeft /></span>
      </button>
      <button onClick={props.handleNext}>
        <span><AngleArrowRight /></span>
      </button>
    </HighlightsControlContainer>
  )
}


export default HighlightsControl