import React from 'react'
import styled from 'styled-components'
import Section from '../../../../resuable/section/section'
import { Link } from 'gatsby'
import Previous from '../../../../../assets/icons/Previous.inline.svg'
import Next from '../../../../../assets/icons/Next.inline.svg'

const StyledFeaturePageNav = styled.div`
  width: 100%;
  display: flex;
  div.container {
    position: relative;
    height: 140px;
    flex: 50%;
    display: flex;
    flex-direction: column;
    img {
      width: 50vw;
      height: 100%;
      object-fit: cover;
      filter: brightness(10%);
      transition: 1s all;
    }
    div {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding: 0 20px;
        color: #fff;
        font-weight: bold;
        font-size: 24px;
      }
    }
    &:hover {
      img {
        filter: brightness(40%);
        transition: 1s all;
      }  
    }
  }
  div.container__prev {
    margin-right: 40px;
    &:hover {
      border-top: 4px solid #d4d4d4;
    }
  }
  div.container__next {
    &:hover {
      border-top: 4px solid #d4d4d4;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    div.container {
      flex: 100%;
      img {
        width: 100vw;
        height: 100%;
      }
    }
    div.container__prev {
      margin-right: 0px;
    }
    div.container__next {
      
    } 
  }
`

const FeaturePageNav = ({ navigation }) => {
  const { previous_button, next_button } = navigation;
  return (
    <Section
      fullwidth={true}
      padding="0px"
    >
      <StyledFeaturePageNav>
        <Link to={`/features/${previous_button.button_link}`}>
          <div className="container container__prev">
            <img src={previous_button.background_image.source_url} alt="previous" />
            <div>
              <Previous /><span>{previous_button.button_label}</span>
            </div>
          </div>
        </Link>
        <Link to={`/features/${next_button.button_link}`}>
          <div className="container container__next">
            <img src={next_button.background_image.source_url} alt="next" />
            <div>
              <span>{next_button.button_label}</span><Next />
            </div>
          </div>
        </Link>
      </StyledFeaturePageNav>
    </Section>
  )
}

FeaturePageNav.defaultProps = {
  nextFeature: {
    name: '',
    link: '',
    img: ''
  },
  prevFeature: {
    name: '',
    link: '',
    img: ''
  },
}

export default FeaturePageNav