import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/resuable/layout/layout';
import FeaturePageHero from '../../components/page/feature-components/feature-page/feature-page-hero/feature-page-hero'
import FeaturePageHighlights from '../../components/page/feature-components/feature-page/feature-page-highlights/feature-page-highlights'
import FeaturePageSummary from '../../components/page/feature-components/feature-page/feature-page-summary/feature-page-summary'
import FeaturePageTestimonial from '../../components/page/feature-components/feature-page/feature-page-testimonial/feature-page-testimonial'
import FeaturePageNav from '../../components/page/feature-components/feature-page/feature-page-nav/feature-page-nav'
import Joinus from '../../components/resuable/joinus/joinus'
import SEO from '../../components/resuable/seo/seo'
import HomeTestimonialSection from '../../components/page/home-components/home-testimonial-section/home-testimonial-section'



const FeaturePageTemplate = ({ data }) => {
  // const { acf } = data.wordpressAcfFeaturepages;
  const { acf } = data.wordpressPage;
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = data.wordpressPage.yoast_meta;
  return (
    <Layout>
      <SEO 
        title={yoast_wpseo_title}
        description={yoast_wpseo_metadesc}
      />
      <FeaturePageHero 
        hero={acf.hero}
      />
      <FeaturePageHighlights 
        highlights={acf.highlights}
      />
      <FeaturePageSummary 
        summary={acf.summary}
      />
      {/* <FeaturePageTestimonial 
        testimonial={acf.testimonial}
      /> */}
      <HomeTestimonialSection />
      <FeaturePageNav 
        navigation={acf.navigation}
      />
      <Joinus />
    </Layout>
  )
}

export default FeaturePageTemplate

export const query = graphql`
  
  query featurePage($slug: String!) {
    wordpressPage(slug: {eq: $slug}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        hero {
          page_title
          page_subtitle
          youtube_video
          youtube_video_placeholder {
            source_url
          }
        }
        highlights {
          title
          body
          image {
            source_url
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        summary {
          title
          body
          image {
            source_url
          }
        }
        testimonial {
          name
          text
          company
          
        }
        navigation {
          previous_button {
            button_label
            button_link
            background_image {
              source_url
            }
          }
          next_button {
            button_label
            button_link
            background_image {
              source_url
            }
          }
        }
      }
    }
  }
`
