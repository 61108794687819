import React, { useState } from 'react'
import styled from 'styled-components'

import Section from '../../../../resuable/section/section'
import HighlightsView from './highlights-view/highlights-view'
import HighlightsControl from './highlights-control/highlights-control'

import { placeholderImage } from '../../../../../utils/helpers/generator'
import { colors } from '../../../../../utils/styles/styles'
import Row from '../../../../resuable/row/row'


const highlights = [
  {
    heading: 'lorem ipsum dolar set 1',
    body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae similique nisi cupiditate earum ea perspiciatis quam blanditiis dolores, quibusdam dicta?',
    image: placeholderImage(400, 300, 'd31')
  },
  {
    heading: 'lorem ipsum dolar set 2',
    body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae similique nisi cupiditate earum ea perspiciatis quam blanditiis dolores, quibusdam dicta?',
    image: placeholderImage(400, 300, '0cd')
  },
  {
    heading: 'lorem ipsum dolar set 3',
    body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae similique nisi cupiditate earum ea perspiciatis quam blanditiis dolores, quibusdam dicta?',
    image: placeholderImage(400, 300, 'dfa')
  },
]

const FeaturePageHighlightsContainer = styled.div`
  position: relative;
  width: auto;
  margin: 18rem 0;
  @media (max-width: 768px) {
    margin: 2rem 0rem;
    padding: 2rem 2rem;
  }
`

/* Actual Component */

const FeaturePageHighlights = (props) => {
  const [index, setActiveIndex] = useState(0);

  const handlePrevious = () => {
    if (index > 0) {
      setActiveIndex(index - 1)
    } else {
      setActiveIndex(highlights.length - 1)
    }
  }

  const handleNext = () => {
    if (index < 1) {
      setActiveIndex(index + 1)
    } else {
      setActiveIndex(0)
    }
  }

  console.log(props.highlights);

  return (
    <Section
      fullwidth={true}
      padding="0px"
      justifyContent="center"
      background={colors.black}
    >
      <FeaturePageHighlightsContainer>
        <Row
          fullwidth={false}
        >
          {
            index === 0 && (
              <HighlightsView
                highlight={props.highlights[index]}
                index={index}
              />
            )
          }
          {
            index === 1 && (
              <HighlightsView
                highlight={props.highlights[index]}
                index={index}
              />
            )
          }
          {
            index === 2 && (
              <HighlightsView
                highlight={props.highlights[index]}
                index={index}
              />
            )
          }
          <HighlightsControl
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        </Row>
      </FeaturePageHighlightsContainer>
    </Section>
  )
}

FeaturePageHighlights.defaultProps = {

}

export default FeaturePageHighlights