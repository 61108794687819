/**
 * @props (hightlight)
 * ! Each highlight is composed of title, body and image
 * ! From the image we are utilising localfile.childImageSharp
 */


import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Fade from 'react-reveal/Fade'

import Row from '../../../../../resuable/row/row'
import Column from '../../../../../resuable/column/column'
import { placeholderImage } from '../../../../../../utils/helpers/generator'

const HighlightsViewContainer = styled.div`
  min-height: 300px;
  .container__text {
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .container__image {
    height: 100%;
    width: 50%;
    .react-reveal {
      height: 100%;
      width: 100%;
    }
    img {
      width: 400px;
      height: 300px;
      object-fit: cover;
      border-radius: 5px;
    }
    .sharp_image {
      height: 300px;
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .container__text {
      width: 100%;
      margin: 0px;
      margin-bottom: 10px;
    }
    .container__image {
      width: 100%;
      margin: 0px;
      margin-bottom: 10px;
    }
  }
`

const HighlightsView = ({ highlight }) => {
  console.log(highlight)
  return (
    <HighlightsViewContainer>
      <Row>
        <Column className="container__text">
          <Fade>
            <h2>{highlight.title}</h2>
            <p>{highlight.body}</p>
          </Fade>
        </Column>

        <Column className="container__image">
          <Fade delay={500} right>
            {
              highlight.image !== null ? (
                <img src={highlight.image.source_url} alt={highlight.title} />
              ) : (
                <img src={placeholderImage(400, 300)} alt={highlight.title} />
              )
            }
          </Fade>
        </Column>

      </Row>
    </HighlightsViewContainer>
  )
}

HighlightsView.defaultProps = {

}

export default HighlightsView