import React, { useState } from 'react'
import styled from 'styled-components'
import Section from '../../../../resuable/section/section'

import Papyrus from '../../../../../images/patterns/papyrus-dark/papyrus-dark.png'
import { ButtonType } from '../../../../resuable/button'
import { Link } from 'gatsby'
import ModalAdapter from '../../../../resuable/modal/ModalAdapter'
import PlayButton from '../../../../../assets/icons/Play.inline.svg'
import VideoPlaceholder from '../../../../../images/feature-page/hero/VideoPlaceholder.jpg'

import './feature-page-hero.scss'

const FeaturePageHeroContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 6rem 0rem 2rem;
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  div.container__text {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h1 {
      margin-bottom: 10px;
      font-size: 36px;
    }
    p {
      font-size: 1.8rem;
    }
  }
  div.container__media {
    position: relative;
    width: 100%;
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img {
      cursor: pointer;
      width: 100%;
      max-width: 800px;
      height: 450px;
      object-fit: cover;
      border-radius: 5px;
    }
    svg {
      cursor: pointer;
      position: absolute;
      top: 38%;
      transition: 0.4s all;
      &:hover {
        transition: 0.4s all;
        transform: scale(1.08);
      }
    }
  }
  div.container__buttons {
    width: 100%;
    padding: 0rem 0 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    a:not(:last-child) {
      margin-right: 20px;
    }
  }
  @media (max-width: 480px) {
    margin: 6rem 0rem 2rem;
    padding: 3rem 2rem;
    div.container__text {
      h1 {
        font-size: 24px;
      }
    }
    div.container__media {
      img {
        width: 320px;
        height: 240px;
      }
    }
    div.container__buttons {
      padding: 0rem 0 2rem;
      flex-direction: column;
      align-items: center;
      a:not(:last-child) {
        margin-right: 0px;
      }
      > * {
        padding: 10px 0px;
      }
    }
  }
`

const FeaturePageHero = ({ hero }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }
  const afterOpenModal = () => {
    console.log('Closed');
  }
  return (
    <Section
      fullwidth={true}
      padding="0px"
      isCentered={true}
      background={`url('${Papyrus}') repeat`}
      backgroundSize="auto"
    >
      <FeaturePageHeroContainer>
        <div className="container__text">
          <h1>{hero.page_title}</h1>
          <p>{hero.page_subtitle}</p>
        </div>
        <div className="container__media">
          <img 
            onClick={openModal}
            src={hero.youtube_video_placeholder.source_url}
          />
          <PlayButton 
            onClick={openModal}
          />
          <ModalAdapter
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            className="featurepagehero"
          >
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${hero.youtube_video}?autoplay=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          </ModalAdapter>
        </div>
        <div className="container__buttons">
          <a href="https://app.leadmirror.com/" target="_blank" rel="noopener noreferrer">
            <ButtonType.Base>Get Started for free</ButtonType.Base>
          </a>
          <Link to="/pricing">
            <ButtonType.Ghost variant="default">Try Demo</ButtonType.Ghost>
          </Link>
        </div>
      </FeaturePageHeroContainer>
    </Section>
  )
}

FeaturePageHero.defaultProps = {
  hero: {
    page_title: `Feature X does Y`,
    page_subtitle: 'In-Page allows you to reverse engineer the entire SEO strategy of any given webpage by providing the following data on a daily basis.',
    youtube_video: '6_lI315vHuQ',
  }
}

export default FeaturePageHero
