import React from 'react'
import styled from 'styled-components'
import Section from '../../../../resuable/section/section'
import { dimensions, fonts } from '../../../../../utils/styles/styles'

const FeaturePageTestimonialContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div.container__inner {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      max-width: ${dimensions.siteWidth};
      text-align: center;
      font-style: italic;
      font-family: ${fonts.sec};
      font-weight: 400;
      justify-content: center;
      line-height: 165%;
      word-wrap: break-word;
    }
    span.name {
      font-size: 18px;
      color: #fff;
    }
    span.company {
      color: #dfdfdf;
    }
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
  }
`

const FeaturePageTestimonial = ({ testimonial }) => {
  return (
    <Section
      fullwidth={true}
      padding="0px 0px"
      background="url('https://source.unsplash.com/collection/216733/850x480') center center no-repeat"
      color="#fff"
    >
      <FeaturePageTestimonialContainer>
        <div className="container__inner">
          <h4>“{testimonial.text}”</h4>
          <div className="contianer__author">
            <span className="name"> - {testimonial.name}, </span>
            <span className="company">{testimonial.company}</span>
          </div>
        </div>
      </FeaturePageTestimonialContainer>
    </Section>
  )
}

FeaturePageTestimonial.defaultProps = {
  testimonial: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic necessitatibus aperiam sed porro dolore. Quia id sequi maxime quidem mollitia?`,
  author: `- Omar Mohammad`
}

export default FeaturePageTestimonial
