import React, { Children } from 'react'
import styled from 'styled-components'
import { placeholderImage } from '../../../../../../utils/helpers/generator';


const StyledFooterSummary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 200px;
    margin: 0rem 0rem 3rem;
  }
  img {
    height: 200px;
    width: 200px;
    margin: 0rem 0rem 3rem;
  }
  h5 {
    margin: 0rem 0rem 0.8rem;
    text-align: center;
  }
  p {
    text-align: center;
    line-height: 130%;
  }
  @media (max-width: 768px) {
    img {
      height: 160px;
      width: 160px;
    }
  }
`

const FeatureSummary = ({ summaryItem }) => {
  const { title, body, image } = summaryItem;
  return (
    <StyledFooterSummary>
      {
        summaryItem.image !== null ? (
          <img src={image.source_url} alt={title}/>
        ) : null
      }
      <h5>{title}</h5>
      <p>{body}</p>
    </StyledFooterSummary>
  )
}

FeatureSummary.defaultProps = {
  image: placeholderImage(200, 200, '#0a4'),
  title: 'A single, simplified workflow',
  body: 'Get all the tools you need to deploy and manage your site. Replace your hosting infrastructure, continuous integration, and deployment pipeline with a single workflow.'
}

export default FeatureSummary
